<template>
  <div>
    <div class="dashboard__container--body pt-3 pb-5 mb-5">
      <Loader v-if="(!group || !group.id) || performingRequest" />
      <div class="dashboard__container--body--col flex" style="width:100%;" v-if="group && group.id && (group.id === $route.params.id)">
        <GroupCard :group="group" :approved="isApproved" :showLink="false"  :truncated="false" />
      </div>
    </div>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
import GroupCard from '@/components/GroupCard.vue'
import * as moment from 'moment'

export default {
  name: 'group',
  data: () => ({
    performingRequest: false,
  }),
  created() {
    this.$store.dispatch("getGroupFromId", this.$route.params.id)
  },
  mounted() {
    this.$nextTick()
  }, 
  computed: {
    ...mapState(['userProfile', 'currentUser', 'group']),
    isApproved() {
      return ((this.userProfile && this.userProfile.phoneVerified == 'approved'))
    },
    path() {
      return this.$route.params.id
    },
    city() {
      if (this.group && this.group.location) {
        let myArray = this.group.location.split(" ")
        return myArray[0]
      }
    },
    state() {
      if (this.group && this.group.location) {
        let myArray = this.group.location.split(" ")
        return myArray[1]
      }
    },
    stampData() {
      return this.formatDate(this.group.created)
    },
    jobPosting() {
      if (this.group && this.group.created) {
        return {
          "@context": "https://schema.org",
          "@type": "JobPosting",
          "url": "https://jumpstaffing.com/jobs/apply/" + this.$route.params.id,
          "title": this.group.title,
          "description": this.group.description,
          "employmentType" : ["CONTRACTOR", "FULL_TIME", "PART_TIME", "TEMPORARY"],
          "hiringOrganization" : {
            "@type" : "Organization",
            "name" : "Jump Staffing",
            "sameAs" : "https://jumpstaffing.com",
            "logo" : "https://firebasestorage.googleapis.com/v0/b/mvpes-25aef.appspot.com/o/logos%2FJump%20Logo%20Official%20(2).png?alt=media&token=b05c3dc2-22d7-4f2e-8085-2e1cfbdb47b9"
          },
          "jobLocation": {
            "@type": "Place",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": this.city,
              "addressRegion": this.state,
              "addressCountry": "US"
            },
          },
          "directApply": true,
          "baseSalary": {
            "@type": "MonetaryAmount",
            "currency": "USD",
            "value": {
              "@type": "QuantitativeValue",
              "value": this.group.startingPay,
              "unitText": "HOUR"
            }
          },
          "datePosted": moment(this.group.created.toDate()).format('YYYY-MM-DD'),
          "validThrough": moment(this.group.created.toDate()).add(1, 'years').format('YYYY-MM-DD'),
          "identifier": {
            "@type": "PropertyValue",
            "name": this.group.title,
            "value": this.group.id
          },
          "skills": "Required Skills",
          "industry": "Industry Name",
          "responsibilities": "Job Responsibilities",
          "qualifications": "Job Qualifications",
        }
      }
    }
  },
  
  components: {
    Loader,
    GroupCard
  },
  methods: {
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('YYYY-MM-DD')
      } else {
        return null
      }
    },
  },
  metaInfo() {
    return {
      title: 'Job Search',
      titleTemplate: `%s | ${this.group.title}`,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: `${this.group.description}`,
      }],
      script: [
        {
          type: 'application/ld+json',
          json: this.jobPosting
        }
      ],
    }
  },
  beforeDestroy() {
    this.group = null
    delete this.group
    this.$store.dispatch("clearGroupState")
  },
  destroyed() {
    this.group = null
    delete this.group
    this.$store.dispatch("clearGroupState")
  },
}
</script>